<template>
  <q-dialog v-model="dialogFinancial">
    <q-card style="min-width: 800px">
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title> Novo PI </q-toolbar-title>
        <q-btn flat round dense icon="mdi-close" @click="onClose" />
      </q-toolbar>

      <q-banner class="bg-purple text-white q-ma-sm" rounded>
        <q-item>
          <q-item-section top avatar>
            <q-icon name="mdi-information-outline" size="lg" />
          </q-item-section>
          <q-item-section>
            <q-item-label caption lines="3" class="text-white">
              <strong>Atenção ao formato correto do arquivo! </strong>
              O mesmo deverá ser separado por ";" e conter as seguintes colunas: "progid", "data inicio", "data fim",
              "pi provisionado" e "custo provisionado". <br />
              Em caso de dúvidas, você pode fazer o download do nosso modelo clicando
              <a class="link" href="https://afilio-v3.s3.amazonaws.com/Criar_Pi.csv">aqui</a>.
            </q-item-label>
          </q-item-section>
        </q-item>
      </q-banner>

      <q-card-section class="row scroll q-col-gutter-md" style="max-height: 80vh">
        <div class="col-12">
          <q-file outlined v-model="params.csv" accept=".csv" label="Selecionar Arquivo (CSV)" @input="changeCSV">
            <template v-slot:prepend>
              <q-icon name="attach_file" @click.stop />
            </template>
            <template v-slot:append>
              <q-btn icon="close" @click.stop="params.csv = null" flat round dense />
            </template>

            <template v-slot:hint> CSV </template>
          </q-file>
        </div>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right" class="q-pa-md">
        <q-btn outline :label="$t('cancel')" no-caps padding="sm lg" color="primary" @click="onClose" />
        <q-btn unelevated label="Criar" no-caps padding="sm lg" color="green" @click="onCreate" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
export default {
  name: 'FormPI',
  mixins: [FinancialMixin],
  data() {
    return {
      params: {
        csv: null
      },
      formDataCSV: null
    };
  },
  methods: {
    changeCSV(file) {
      console.log('file', file);

      this.formDataCSV = new FormData();
      this.formDataCSV.append('csv_file', file);
    },

    onClose() {
      this.ActionSetDialogFinancial(false);
    },

    async onCreate() {
      if (this.formDataCSV) {
        try {
          this.onLoading(true);

          const { data, status } = await this.$http.post(
            `${process.env.VUE_APP_FINANCE}/api/pi/upload`,
            this.formDataCSV,
            {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            }
          );
          if (status === 200 || status === 201) {
            this.successNotify(data.data.msg);
            this.ActionSetDialogFinancial(false);
          }
        } catch (error) {
          this.errorNotify(error.response.data.data.msg);
        } finally {
          this.onLoading(false);
        }
      }
    }
  }
};
</script>

<style>
.link {
  color: white;
}
</style>
