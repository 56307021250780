<template>
  <q-layout>
    <div class="page-header">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">PI</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="PI" exact />
      </q-breadcrumbs>
    </div>
    <q-page padding>
      <header-PI></header-PI>
      <list-PI></list-PI>
      <form-PI></form-PI>
      <info-PI></info-PI>
      <edit-PI></edit-PI>
    </q-page>
  </q-layout>
</template>

<script>
import HeaderPI from '../../components/financial/HeaderPI.vue';
import ListPI from '../../components/financial/ListPI.vue';
import FormPI from '../../components/financial/FormPI.vue';
import InfoPI from '../../components/financial/InfoPI.vue';
import EditPI from '../../components/financial/EditPI.vue';

export default {
  name: 'PI',
  components: { HeaderPI, ListPI, FormPI, InfoPI, EditPI }
};
</script>
