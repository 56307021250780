<template>
  <q-table
    flat
    bordered
    :data="pis"
    :columns="columns"
    row-key="id"
    :pagination="pagination"
    class="sticky-column-table"
  >
    <!-- <template v-slot:body-cell-initial_date="props">
      <q-td :props="props" color="blue-1">
        <q-chip dense color="primary" class="text-white">
          {{ props.row.initial_date }}
        </q-chip>
        <q-popup-edit
          v-model="props.row.initial_date"
          title="Nova data de pagamento"
        >
          <date-input
            v-model="props.row.initial_date"
            outlined
            label="Nova data de pagamento"
          />
        </q-popup-edit>
      </q-td>
    </template> -->

    <template v-slot:body-cell-actions="props">
      <q-td :props="props">
        <q-btn
          unelevated
          round
          flat
          icon="mdi-pencil-outline"
          color="primary"
          @click="ActionSetDialogEditPi({ value: props.row })"
        >
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px">
            {{ $t('edit') }}
          </q-tooltip>
        </q-btn>

        <q-btn unelevated round flat icon="mdi-minus-circle" color="red" @click="deletePi({ value: props.row })">
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Excluir </q-tooltip>
        </q-btn>

        <q-btn
          unelevated
          round
          flat
          icon="mdi-information"
          color="indigo"
          @click="ActionSetDialogFinancialPI({ value: props.row })"
        >
          <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Informações </q-tooltip>
        </q-btn>
      </q-td>
    </template>

    <template v-slot:bottom>
      <!-- <pagination-table
        @change="getInvoicesPayments"
        :data="reqPagination"
        :pagination="pagination"
      /> -->
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        Total:{{ reqPagination.total }}
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
  </q-table>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
// import PaginationTable from "../../widgets/PaginationTable.vue";
// import DateInput from "../../widgets/inputsGlobal/DateInput.vue";

export default {
  name: 'ListPayInvoices',
  // components: { DateInput },
  mixins: [FinancialMixin],

  created() {
    this.getPIs(null, '%26states=[4]%26perPage=' + this.pagination.rowsPerPage);
  },

  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getPIs(`page=${this.currentPage}`, `%26perPage=${this.pagination.rowsPerPage}`);
      }
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  data() {
    return {
      params: {
        payout: null,
        paymentDay: null
      },
      columns: [
        {
          name: 'id',
          required: true,
          label: 'Número PI',
          align: 'left',
          field: 'id',
          sortable: true
        },
        {
          name: 'campaign_id',
          required: true,
          label: 'Prog ID',
          align: 'left',
          field: 'campaign_id',
          sortable: true
        },
        {
          name: 'name',
          required: true,
          label: 'Campanha',
          align: 'left',
          field: 'name',
          sortable: true
        },
        {
          name: 'initial_date',
          required: true,
          label: 'Data Início',
          align: 'left',
          field: 'initial_date',
          format: (val) => this.$moment(val).format('L'),
          sortable: true
        },
        {
          name: 'final_date',
          required: true,
          label: 'Data Fim',
          align: 'left',
          field: 'final_date',
          format: (val) => this.$moment(val).format('L'),
          sortable: true
        },
        {
          name: 'provisioned_pi',
          required: true,
          label: 'PI Provisionado',
          align: 'left',
          field: 'provisioned_pi',
          sortable: true
        },
        {
          name: 'provisioned_cost',
          required: true,
          label: 'Custo Provisionado',
          align: 'center',
          field: 'provisioned_cost',
          sortable: true
        },
        {
          name: 'validated_pi',
          required: true,
          label: 'PI Validado',
          align: 'center',
          field: 'validated_pi',
          sortable: true
        },
        {
          name: 'validated_cost',
          required: true,
          label: 'Custo Validado',
          align: 'center',
          field: 'validated_cost',
          sortable: true
        },
        {
          name: 'actions',
          required: true,
          label: 'Ações',
          align: 'center',
          field: 'actions',
          sortable: true
        }
      ],
      //currentPage: 1,
      pagination: {
        sortBy: 'trademark',
        descending: true,
        rowsPerPage: 12
      }
    };
  },
  methods: {
    deletePi(data) {
      console.log('data', data.value.id);
      this.showDialog(
        'Alerta',
        `Tem certeza que deseja apagar o PI selecionado (ID: ${data.value.id})?`,
        () => this.onOk(data.value.id),
        this.onCancel
      );
    },
    async onOk(id) {
      const { data, status } = await this.deletePI(id);
      if (status === 200) {
        this.successNotify(data.data.msg);
        this.getPIs();
      }
    }
  }
};
</script>
<style lang="sass">
.sticky-column-table
  max-width: 2600px
  thead tr:last-child th:last-child
    background-color: #fff
  td:last-child
    background-color: #fff
  th:last-child,
  td:last-child
    position: sticky
    right: 0
    z-index: 1
</style>
