<template>
  <q-dialog v-model="dialogEditPI">
    <q-card style="min-width: 800px">
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title>Edição de PI</q-toolbar-title>
        <q-btn flat round dense icon="mdi-close" @click="onClose" />
      </q-toolbar>

      <q-card-section class="row scroll q-col-gutter-md">
        <div class="col-6">
          <date-input outlined label="Data início*" :v-model="initialDate" @update="initialDate = $event" />
        </div>

        <div class="col-6">
          <date-input outlined label="Data fim" :v-model="finalDate" @update="finalDate = $event" />
        </div>

        <div class="col-3">
          <q-input outlined type="text" label="PI Provisionado*" v-model="infoEditPIs.provisioned_pi" />
        </div>
        <div class="col-3">
          <q-input outlined type="text" label="Custo Provisionado*" v-model="infoEditPIs.provisioned_cost" />
        </div>
        <div class="col-3">
          <q-input outlined type="text" label="PI Validado*" v-model="infoEditPIs.validated_pi" />
        </div>
        <div class="col-3">
          <q-input outlined type="text" label="Custo Validado*" v-model="infoEditPIs.validated_cost" />
        </div>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right" class="q-pa-md">
        <q-btn outline :label="$t('cancel')" no-caps padding="sm lg" color="primary" @click="onClose" />
        <q-btn unelevated label="Salvar Alteração" no-caps padding="sm lg" color="green" @click="onEdit" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
import DateInput from '../../widgets/inputsGlobal/DateInput';

export default {
  name: 'EditPI',
  components: { DateInput },
  mixins: [FinancialMixin],
  data() {
    return {};
  },

  computed: {
    initialDate: {
      get() {
        return this.infoEditPIs.initial_date;
      },
      set(date) {
        this.infoEditPIs.initial_date = date;
      }
    },

    finalDate: {
      get() {
        return this.infoEditPIs.final_date;
      },
      set(date) {
        this.infoEditPIs.final_date = date;
      }
    }
  },

  methods: {
    onClose() {
      this.ActionSetDialogEditPi(false);
    },
    async onEdit() {
      this.onLoading(true);
      try {
        const { status } = await this.$http.post('/redirect', {
          app: 'finance',
          path: '/api/pi/pis_update',
          params: JSON.stringify({
            data: {
              data: JSON.stringify([
                {
                  id: this.infoEditPIs.id,
                  initial_date: this.$moment(this.infoEditPIs.initial_date).format('DD/MM/YY'),
                  final_date: this.$moment(this.infoEditPIs.final_date).format('DD/MM/YY'),
                  provisioned_pi: this.infoEditPIs.provisioned_pi,
                  provisioned_cost: this.infoEditPIs.provisioned_cost,
                  validated_pi: this.infoEditPIs.validated_pi,
                  validated_cost: this.infoEditPIs.validated_cost
                }
              ])
            }
          })
        });
        if (status === 200 || status === 201) {
          this.successNotify('PI Atualizado com sucesso!');
          this.ActionSetDialogEditPi(false);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<style>
.link {
  color: white;
}
</style>
