<template>
  <div class="q-mb-sm">
    <q-toolbar class="q-pr-none q-mb-md">
      <q-space />
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> Filtrar PI </q-tooltip></q-btn
      >
      <!-- @click="ActionSetDialogCampaign(true)" -->
      <q-btn
        unelevated
        color="primary"
        class="q-px-lg"
        icon="mdi-plus"
        label="Criar PI"
        no-caps
        @click="ActionSetDialogFinancial(true)"
      />
    </q-toolbar>
    <q-card flat v-if="filter" bordered>
      <q-toolbar>
        <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
        <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
      </q-toolbar>
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-4">
          <q-input buttons :v-model="params.invoiceId" outlined label="Número de PI" type="number" clearable />
        </div>
        <div class="col-4">
          <select-request
            :v-model="params.campaign"
            outlined
            label="Campanha"
            endpoint="/redirect?app=ADVERTISER&path=/api/get/campaign"
            option-label="name"
            option-value="id"
            @update="(value) => (params.campaign = value === null ? null : value.id)"
          />
        </div>
        <div class="col-4">
          <date-input
            outlined
            label="Data de geração"
            :v-model="params.invoiceDate"
            @update="(value) => (params.invoiceDate = value)"
            clearable
            range
          />
        </div>

        <div class="col-12 text-right">
          <q-btn color="primary" label="Limpar" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
          <q-btn color="positive" label="Buscar" no-caps unelevated class="q-px-md" @click="onFilterCampaigns" />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';

export default {
  name: 'HeaderPayInvoices',
  mixins: [FinancialMixin],
  components: { SelectRequest, DateInput },
  data() {
    return {
      filter: true,
      params: {
        campaign: null,
        invoiceId: null,
        invoiceDate: null
      }
    };
  },
  methods: {
    clearFilter() {
      for (let i in this.params) this.params[i] = null;
      // this.ActionSetFilterInvoicesPayments();
      // this.getInvoicesPayments();
      this.ActionSetFilterPis('%26'), this.getPIs(null, '%26states=[4]');
    },

    onFilterCampaigns() {
      let filterSelect = [];

      if (this.params.campaign) filterSelect.push(`campaign=${this.params.campaign}`);
      if (this.params.invoiceId) filterSelect.push(`id=${this.params.invoiceId}`);

      if (this.params.invoiceDate && !this.params.invoiceDate.includes('Não Informado')) {
        let pre_start_date = this.params.invoiceDate.split(' - ')[0].split('/'),
          pre_end_date = this.params.invoiceDate.split(' - ')[1].split('/'),
          start_date = `${pre_start_date[2]}-${pre_start_date[1]}-${pre_start_date[0]}`,
          end_date = `${pre_end_date[2]}-${pre_end_date[1]}-${pre_end_date[0]}`;

        filterSelect.push(`initial_date=${start_date}`);
        filterSelect.push(`final_date=${end_date}`);
      }

      // Needs to change de '&' to %26 to work
      if (filterSelect.length) {
        this.ActionSetFilterPis(filterSelect.join('%26'));
        this.getPIs();
      } else this.clearFilter();
    }
  }
};
</script>

<style></style>
