<template>
  <q-dialog v-model="dialogFinancialPI" @before-show="getInfoPIs" @hide="rows = []">
    <q-card style="min-width: 1280px" v-if="infoPIs">
      <q-toolbar class="bg-primary text-white">
        <q-toolbar-title> Info PI </q-toolbar-title>
        <q-btn flat round dense icon="mdi-close" @click="onClose" />
      </q-toolbar>
      <q-card-section class="scroll" style="max-height: 80vh">
        <div class="row q-col-gutter-lg">
          <div class="col-12">
            <q-card flat bordered>
              <q-card-section>
                <p><strong> Campanha:</strong> {{ infoPIs.name }}</p>
                <p><strong> ID:</strong> {{ infoPIs.campaign_id }}</p>
                <p>
                  <strong> Período:</strong>
                  {{ $moment(infoPIs.initial_date).format('L') }} -
                  {{ $moment(infoPIs.final_date).format('L') }}
                </p>
              </q-card-section>
            </q-card>
          </div>

          <div class="col-12">
            <q-table flat bordered :data="rows" :columns="columns" row-key="id" hide-bottom />
          </div>

          <div class="col-12">
            <q-card v-if="rows[0]" flat bordered>
              <table class="fit q-pa-md">
                <thead>
                  <tr>
                    <th>Atribuição</th>
                    <th>Pi Validado</th>
                    <th>Custo Validade</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <q-input
                        outlined
                        v-model="rows[0].afilio_assignment"
                        clearable
                        clear-icon="close"
                        type="number"
                        label="Afilio"
                      >
                        <template v-slot:after> % </template>
                      </q-input>
                    </td>
                    <td>R$ {{ validatePiAfilio }}</td>
                    <td>R$ {{ validateCostAfilio }}</td>
                  </tr>
                  <tr>
                    <td>
                      <q-input
                        outlined
                        v-model="hiMidiaAtribution"
                        clearable
                        clear-icon="close"
                        type="number"
                        label="Hi-Midia"
                      >
                        <template v-slot:after> % </template>
                      </q-input>
                    </td>
                    <td>R$ {{ validatePiHiMidia }}</td>
                    <td>R$ {{ validateCostHiMidia }}</td>
                  </tr>
                </tbody>
              </table>
            </q-card>
          </div>

          <div class="col-12" v-if="rows[0]">
            <q-card flat bordered>
              <q-card-section>
                <div class="q-my-md">
                  <q-input outlined type="number" label="Prazo de vencimento (em dias)" v-model="rows[0].due" />
                </div>
                <div class="q-my-md">
                  <q-input outlined type="textarea" label="Observações Gerais" v-model="rows[0].observation" />
                </div>
              </q-card-section>
            </q-card>
          </div>

          <div class="col-12">
            <q-card flat bordered>
              <q-toolbar class="bg-grey-4">
                <q-toolbar-title> Dados Cadastrais </q-toolbar-title>
              </q-toolbar>

              <q-card-section class="row q-col-gutter-lg">
                <div class="col-4">
                  <q-card bordered flat>
                    <q-toolbar class="bg-grey-2">
                      <q-toolbar-title> Afilio S/A </q-toolbar-title>
                    </q-toolbar>
                    <q-card-section>
                      <p><strong> Razão Social: </strong>Afilio Internet LTDA.</p>
                      <p><strong> Insc. Estadual: </strong>Isento</p>
                      <p><strong> Insc. Municipal: </strong>0.443.261-4</p>
                      <p><strong> CNPJ: </strong>10.190.614/0001-37</p>
                      <p>
                        <strong> Endereço: </strong>Rua Hélios Seelinger, n° 155, We Work, Barra da Tijuca, Rio de
                        Janeiro
                      </p>
                      <p><strong> CEP: </strong>22640-040</p>
                      <p><strong> Telefone: </strong>(21) 2244-8850</p>
                      <p><strong> E-mail: </strong>financeiro@afil.io</p>
                    </q-card-section>
                  </q-card>
                </div>
                <div class="col-4">
                  <q-card bordered flat>
                    <q-toolbar class="bg-grey-2">
                      <q-toolbar-title> Hi-Mídia Internet LTDA. </q-toolbar-title>
                    </q-toolbar>
                    <q-card-section>
                      <p><strong> Razão Social: </strong>Hi-Mídia</p>
                      <p><strong> CNPJ: </strong>07.456.778/0001-59</p>
                      <p><strong> Insc. Estadual: </strong>Isento</p>
                      <p><strong> Insc. Municipal: </strong>0.443.243-6</p>
                      <p>
                        <strong> Endereço: </strong>Rua Hélios Seelinger, n° 155, We Work, Barra da Tijuca, Rio de
                        Janeiro.
                      </p>
                      <p><strong> CEP: </strong>22640-040</p>
                      <p><strong> Telefone: </strong>(21) 2244-8850</p>
                      <p><strong> E-mail: </strong>financeiro@afil.io</p>
                    </q-card-section>
                  </q-card>
                </div>

                <div class="col-4" v-if="rows[0]">
                  <q-card bordered flat>
                    <q-toolbar class="bg-grey-2">
                      <q-toolbar-title>Advertiser</q-toolbar-title>
                    </q-toolbar>
                    <q-card-section v-if="rows[0].campaign_invoice.original_invoice_status == 1">
                      <p>
                        <strong> Razão Social: </strong>
                        {{ rows[0].campaign_invoice.custom_invoice.company_name }}
                      </p>
                      <p>
                        <strong> CNPJ: </strong>
                        {{ rows[0].campaign_invoice.custom_invoice.registration_code }}
                      </p>
                      <p>
                        <strong> Insc. Estadual: </strong>
                        {{ rows[0].campaign_invoice.custom_invoice.inscricao_estadual }}
                      </p>
                      <p>
                        <strong> Insc. Municipal: </strong>
                        {{ rows[0].campaign_invoice.custom_invoice.inscricao_municipal }}
                      </p>
                      <p>
                        <strong> Nome do Contrato: </strong>
                        {{ rows[0].campaign_invoice.custom_invoice.company_name }}
                      </p>
                      <p>
                        <strong> E-mail de Cobrança: </strong>
                        {{ rows[0].campaign_invoice.custom_invoice.email }}
                      </p>
                      <p>
                        <strong> Telefone: </strong>
                        {{ rows[0].campaign_invoice.custom_invoice.phone }}
                      </p>
                      <p>
                        <strong> Endereço: </strong>
                        {{ rows[0].campaign_invoice.custom_invoice.address }}
                      </p>
                      <p>
                        <strong> Cidade: </strong>
                        {{ rows[0].campaign_invoice.custom_invoice.city }}
                      </p>
                      <p>
                        <strong> UF: </strong>
                        {{ rows[0].campaign_invoice.custom_invoice.state }}
                      </p>
                      <p>
                        <strong> CEP: </strong>
                        {{ rows[0].campaign_invoice.custom_invoice.postal_code }}
                      </p>
                    </q-card-section>
                    <q-card-section v-else>
                      <p>
                        <strong> Razão Social: </strong>
                        {{ rows[0].campaign_invoice.original_invoice.company_name }}
                      </p>
                      <p>
                        <strong> CNPJ: </strong>
                        {{ rows[0].campaign_invoice.original_invoice.registration_code }}
                      </p>
                      <p>
                        <strong> Insc. Estadual: </strong>
                        {{ rows[0].campaign_invoice.original_invoice.inscricao_estadual }}
                      </p>
                      <p>
                        <strong> Insc. Municipal: </strong>
                        {{ rows[0].campaign_invoice.original_invoice.inscricao_municipal }}
                      </p>
                      <p>
                        <strong> Nome do Contrato: </strong>
                        {{ rows[0].campaign_invoice.original_invoice.company_name }}
                      </p>
                      <p>
                        <strong> E-mail de Cobrança: </strong>
                        {{ rows[0].campaign_invoice.original_invoice.email }}
                      </p>
                      <p>
                        <strong> Telefone: </strong>
                        {{ rows[0].campaign_invoice.original_invoice.phone }}
                      </p>
                      <p>
                        <strong> Endereço: </strong>
                        {{ rows[0].campaign_invoice.original_invoice.address }}
                      </p>
                      <p>
                        <strong> Cidade: </strong>
                        {{ rows[0].campaign_invoice.original_invoice.city }}
                      </p>
                      <p>
                        <strong> UF: </strong>
                        {{ rows[0].campaign_invoice.original_invoice.state }}
                      </p>
                      <p>
                        <strong> CEP: </strong>
                        {{ rows[0].campaign_invoice.original_invoice.postal_code }}
                      </p>
                    </q-card-section>
                  </q-card>
                </div>
              </q-card-section>
            </q-card>
          </div>
        </div>
      </q-card-section>
      <q-separator />
      <q-card-actions align="right" class="q-pa-md">
        <q-btn outline :label="$t('cancel')" no-caps padding="sm lg" color="primary" @click="onClose" />
        <q-btn unelevated label="Salvar" no-caps padding="sm lg" color="green" @click="onSavePiInfo" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
export default {
  name: 'FormPI',
  mixins: [FinancialMixin],
  data() {
    return {
      params: {
        csv: null
      },
      columns: [
        {
          name: 'id',
          align: 'left',
          label: 'Número PI',
          field: 'id',
          sortable: true
        },
        {
          name: 'campaign_id',
          align: 'left',
          label: 'ID',
          field: 'campaign_id',
          sortable: true
        },
        {
          name: 'name',
          align: 'left',
          label: 'Nome',
          field: 'name',
          sortable: true
        },
        {
          name: 'initial_date',
          align: 'left',
          label: 'Início',
          field: 'initial_date',
          sortable: true,
          format: (val) => this.$moment(val).format('L')
        },
        {
          name: 'final_date',
          align: 'left',
          label: 'Fim',
          field: 'final_date',
          sortable: true,
          format: (val) => this.$moment(val).format('L')
        },
        {
          name: 'invoice_type',
          align: 'left',
          label: 'Praça',
          field: 'invoice_type',
          sortable: true
        },
        {
          name: 'negotiation',
          align: 'left',
          label: 'Negociação',
          field: 'negotiation',
          sortable: true
        },
        {
          name: 'provisioned_pi',
          align: 'left',
          label: 'PI Provisionado',
          field: 'provisioned_pi',
          sortable: true
        },
        {
          name: 'provisioned_cost',
          align: 'left',
          label: 'Custo Provisionado',
          field: 'provisioned_cost',
          sortable: true
        },
        {
          name: 'validated_pi',
          align: 'left',
          label: 'PI Validado',
          field: 'validated_pi',
          sortable: true
        },
        {
          name: 'validated_cost',
          align: 'left',
          label: 'Custo Validado',
          field: 'validated_cost',
          sortable: true
        }
      ],
      roles: [],
      loading_role: false,
      loading_profile: false,
      profiles: [],
      rows: []
    };
  },
  computed: {
    hiMidiaAtribution() {
      return 100 - this.rows[0].afilio_assignment;
    },

    validatePiAfilio() {
      let number = this.price_to_number(this.rows[0].validated_pi);
      return this.number_to_price(number * (this.rows[0].afilio_assignment / 100));
    },

    validateCostAfilio() {
      let number = this.price_to_number(this.rows[0].validated_cost);
      return this.number_to_price(number * (this.rows[0].afilio_assignment / 100));
    },

    validatePiHiMidia() {
      let number = this.price_to_number(this.rows[0].validated_pi);
      return this.number_to_price(number * ((100 - this.rows[0].afilio_assignment) / 100));
    },

    validateCostHiMidia() {
      let number = this.price_to_number(this.rows[0].validated_cost);
      return this.number_to_price(number * ((100 - this.rows[0].afilio_assignment) / 100));
    }
  },
  methods: {
    price_to_number(v) {
      if (!v) {
        return 0;
      }
      v = v.split('.').join('');
      v = v.split(',').join('.');
      return Number(v.replace(/[^0-9.]/g, ''));
    },
    number_to_price(v) {
      if (v == 0) {
        return '0,00';
      }
      v = parseFloat(v);
      v = v.toFixed(2).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
      v = v.split('.').join('*').split(',').join('.').split('*').join(',');
      return v;
    },
    async getInfoPIs() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get(`/redirect?app=finance&path=/api/pi/details/${this.infoPIs.id}`);
        if (status == 200) {
          console.log('data', data.data);
          this.rows.push(data.data);
        }
      } catch (error) {
        this.errorNotify('Não foi possível carregar Informações do PI.');
      } finally {
        this.onLoading(false);
      }
    },
    onClose() {
      this.ActionSetDialogFinancialPI(false);
    },
    async onSavePiInfo() {
      this.onLoading(true);
      try {
        let infoPiParams = {
          afilio_assignment: this.rows[0].afilio_assignment,
          observation: this.rows[0].observation,
          due: this.rows[0].due
        };

        const { data, status } = await this.infoPiUpdate(this.infoPIs.id, infoPiParams);
        if (status === 200) {
          this.successNotify(data.data.msg);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<style></style>
